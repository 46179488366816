import React, { useState } from 'react';
import { Box, Button, InputAdornment, CircularProgress, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useInput } from "../utils/forms"
import logo from '../img/WhoSaidLogo.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { signUp } from '../Api'
import { setUser } from '../App';
import Error from './Error';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { FloatingContainer, PrimaryButton, StyledTextField, StyledInputRow } from './Styles';
import { Row } from 'react-bootstrap';

const Signup = () => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        showPassword: false
    })

    const navigate = useNavigate();

    const { value: username, bind: bindUsername } = useInput("", true);
    const { value: firstName, bind: bindFirstName } = useInput("", true);
    const { value: lastName, bind: bindLastName } = useInput("");
    const { value: email, bind: bindEmail } = useInput("", true);
    const { value: phone, bind: bindPhone } = useInput("");
    const { value: password, bind: bindPassword } = useInput("", true);
    const { value: confirmPassword, bind: bindConfirmPassword } = useInput("", true);
    const [birthDate, setBirthDate] = useState(dayjs().subtract(13, 'year'));

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    }

    const handleLogin = () => {
        navigate("/login");
    }

    const handleSignUp = async event => {
        event.preventDefault();
        setLoading(true);

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }
        if (birthDate > dayjs().subtract(13, 'year')) {
            setError('Must be 13 years or older to make an account');
            setLoading(false);
            return;
        }
        let response;
        try {
            response = await signUp({
                email: email,
                first_name: firstName,
                last_name: lastName,
                username: username,
                birth_date: birthDate.format("YYYY/MM/DD"),
                password: confirmPassword,
                phone_number: phone,
                session_type: "WEB"
            });
            setUser(response);
            localStorage.setItem("username", username);
            setLoading(false);
            navigate("/explore");
        } catch (error) {
            setLoading(false);
            setError('Unable to create user account: ' + error);
            console.error(error);
        }
    };

    const handleDateChange = (newDate: Dayjs | null) => {
        setBirthDate(newDate);
    }

    return (
        <FloatingContainer>
            <div>
                <Row>
                    <img src={logo} style={{ width: '300px', marginBottom: "40px" }} alt="Who Said? I Said!" />
                </Row>
                <div>
                    {error ? <Error errorData={error} /> : ''}
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSignUp}
                    >
                        <StyledInputRow>
                            <StyledTextField
                                id="username"
                                label="Username"
                                type="text"
                                name="username"
                                variant="filled"
                                fullWidth
                                {...bindUsername}
                            />
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="firstName"
                                label="First Name"
                                type="text"
                                name="firstName"
                                autoComplete="given-name"
                                variant="filled"
                                fullWidth
                                {...bindFirstName}
                            />
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="lastName"
                                label="Last Name"
                                type="text"
                                name="lastName"
                                autoComplete="family-name"
                                variant="filled"
                                fullWidth
                                {...bindLastName}
                            />
                        </StyledInputRow>
                        <StyledInputRow>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Birth Date"
                                    inputFormat="MM/DD/YYYY"
                                    variant="filled"
                                    name="birthDate"
                                    value={birthDate}
                                    renderInput={(params) => <StyledTextField fullWidth {...params} />}
                                     onChange={handleDateChange}/>
                            </LocalizationProvider>
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="emailAddress"
                                label="Email Address"
                                type="email"
                                name="emailAddress"
                                autoComplete="email"
                                variant="filled"
                                fullWidth
                                {...bindEmail}
                            />
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="phoneNumber"
                                label="Phone Number"
                                type="tel"
                                name="phoneNumber"
                                autoComplete="tel"
                                variant="filled"
                                fullWidth
                                {...bindPhone}
                            />
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="password"
                                label="Password"
                                type={values.showPassword ? 'text' : 'password'}
                                name="password"
                                autoComplete="new-password"
                                variant="filled"
                                // className={classes.modal}
                                fullWidth
                                {...bindPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="confirmPassword"
                                label="Confirm Password"
                                type={values.showPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                autoComplete="new-password"
                                variant="filled"
                                fullWidth
                                {...bindConfirmPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </StyledInputRow>
                        <Row>
                            <PrimaryButton
                                variant='contained'
                                disabled={loading}
                                onClick={handleSignUp}>
                                {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
                                Create Account
                            </PrimaryButton>
                            <Button
                                variant='outlined'
                                onClick={handleLogin}
                                style={{ marginLeft: "20px", color: 'white' }}
                            >
                                Login
                            </Button>
                        </Row>
                    </Box>
                </div>
            </div>
        </FloatingContainer>
    );
};

export default Signup;
