import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../img/WhoSaidLogoDark.png';
import {Link, useNavigate} from 'react-router-dom';
import {getUser, refreshToken} from "../Api";
import {setUser} from "../App";
import {StyledTextField} from "./Styles";
import {InputAdornment} from "@mui/material";

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Navigation = (props) => {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [tinyScreen, setTinyScreen] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const getProfilePicture = () => {
        if (localStorage.getItem("profilePicture")) {
            return;
        }
        const response = getUser(localStorage.getItem("userId"));
        localStorage.setItem("profilePicture", response["profile_picture"]);
    }
    useEffect(() => {
        async function getProfilePicture() {
            if (localStorage.getItem("profilePicture")) {
                return;
            }
            const response = await getUser(localStorage.getItem("userId"));
            localStorage.setItem("profilePicture", response["profile_picture"]);
        }

        getProfilePicture();
    });

    useEffect(() => {
        const interval = setInterval(async () => {
            if (localStorage.getItem("remember")) {
                console.debug("Getting new refresh token");
                try {
                    const response = await refreshToken();
                    setUser(response);
                } catch (error) {

                }
            }
        }, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    // Effect hook for screen size variations
    useEffect(() => {
        if (window.innerWidth > 767) {
            setTinyScreen(false);
        } else {
            setTinyScreen(true);
        }
    }, [])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleLogout = event => {
        // TODO: Actually submit a logout request to the api
        localStorage.clear();
        navigate("/login");
    };

    return (
        <AppBar style={{backgroundColor: "white", position: "fixed"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    { tinyScreen ? <IconButton onClick={() => setShowSearch(!showSearch)} style={{backgroundColor: props.search ? "#00E0FF" : null}}>
                        <SearchIcon/>
                    </IconButton> : <StyledTextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        id="search"
                        label="Search"
                        value={props.search}
                        onChange={(event) => props.setSearch(event.target.value)}
                    />}
                    <Link to="/explore"
                          style={{marginLeft: "30%", paddingTop: "20px", paddingBottom: "20px", height: "100px"}}
                    >
                        <img src={logo} alt="Who Said?" style={{height: "100%"}}/>
                    </Link>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex'}}}>
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar alt={localStorage.getItem("username")}
                                        src={localStorage.getItem("profilePicture") || "/static/images/avatar/2.jpg"}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key="profile" onClick={() => navigate('/profile')}>
                                <Typography textAlign="center">Profile</Typography>
                            </MenuItem>
                            <MenuItem key="logout" onClick={handleLogout}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
                {showSearch ? <StyledTextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                    id="search"
                    label="Search"
                    value={props.search}
                    onChange={(event) => props.setSearch(event.target.value)}
                    style={{paddingBottom: "10px", width: "100%"}}
                /> : <div></div>}
            </Container>
        </AppBar>
    );
};
export default Navigation;
