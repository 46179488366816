// src/components/login.js
import React, { useState } from "react"
import {
	Box,
	IconButton,
	InputAdornment,
	Button,
	CircularProgress,
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@mui/material'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import { useNavigate, Link } from "react-router-dom";
import { setUser } from '../App';
import { login } from '../Api'
import logo from '../img/WhoSaidLogo.png';
import Error from './Error';
import { PrimaryButton, FloatingContainer, StyledInputRow, StyledTextField } from './Styles';
import { Row } from 'react-bootstrap';

const Login = () => {
	let navigate = useNavigate();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState({
		username: '',
		password: '',
		showPassword: false,
		remember: false
	})

	/** Handler to change values based on TextField input. */
	const handleChange = prop => event => {
		setValues({ ...values, [prop]: event.target.value });
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	}

	const handleRemember = () => {
		setValues({ ...values, remember: !values.remember });
	}

	const createAccount = () => {
		navigate("/signup");
	}

	const handleLogin = async event => {
		event.preventDefault();
		setLoading(true);
		let response;
		try {
			response = await login(values.username, values.password);
			setUser(response);
			setLoading(false);
			localStorage.setItem("username", values.username);
			if (values.remember) {
				localStorage.setItem("remember", true);
			}
			navigate("/explore");
		} catch (error) {
			setLoading(false);
			setError('Unable to login to user account ' + error);
			console.error('Error.js: ', error);
		}
	}

	return (
			<FloatingContainer>
				<div>
					<Row>
						<img src={logo} style={{ width: '300px', marginBottom: "40px" }} alt="Who Said? I Said!" />
					</Row>
					<div>
						{error ? <Error errorData={error} /> : ''}
						<Box
								component="form"
								noValidate
								autoComplete="off"
								onSubmit={handleLogin}
						>
							<StyledInputRow>
								<StyledTextField
										id="filled-email-input"
										label="Username"
										type="username"
										autoComplete="Username"
										value={values.username}
										onChange={handleChange('username')}
										variant="filled"
										fullWidth />
							</StyledInputRow>
							<StyledInputRow>
								<StyledTextField
										id="filled-adornment-password"
										label="Password"
										type={values.showPassword ? 'text' : 'password'}
										margin="normal"
										autoComplete="Password"
										value={values.password}
										onChange={handleChange('password')}
										InputProps={{
											endAdornment: (
													<InputAdornment position="end">
														<IconButton
																edge="end"
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
														>
															{values.showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
											),
										}}
										variant="filled"
										fullWidth
								/>
							</StyledInputRow>
							<Row>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox checked={values.remember} onChange={handleRemember} style={{ color: 'white' }}/>}
										label="Remember Me" />
								</FormGroup>
							</Row>
							<Row className="pb-2">
								<Link to="/forgotpassword" style={{ textDecoration: "none", color: "white"}}>
									Forgot Password?
								</Link>
							</Row>
							<Row>
								<PrimaryButton
								        variant='contained'
								        disabled={loading}
								        type="submit"
								        onClick={handleLogin}
								>
									{loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
									Login
								</PrimaryButton>
								<Button
										variant='outlined'
										onClick={createAccount}
										style={{ marginLeft: "20px", color: 'white' }}
								>
									Create Account
								</Button>
							</Row>
						</Box>
					</div>
				</div>
			</FloatingContainer>
	);
}

export default Login;
