import React, {useRef, useState} from 'react';
import {Box, CircularProgress} from '@mui/material';
import {SecondaryButton, StyledTextField} from './Styles';
import {makePost} from '../Api';
import {Col, Row} from 'react-bootstrap';
/* global google */

const Filter = require('bad-words');

export const createTags = (tags) => {
    // Split based on whitespace, commas, or hashtags
    let tag_arr = tags.split(/[\s,#]+/);
    // Remove any empty strings
    tag_arr = tag_arr.filter((str) => str !== '');
    // We want these to actually be hashtags so append the hashtag if not already there
    tag_arr.forEach((tag, index, arr) => {
        if (!tag.startsWith("#")) {
            arr[index] = `#${tag}`;
        }
    })
    return tag_arr;
}

const WritePost = (props) => {
    const [post, setPost] = useState('');
    const [definition, setDefinition] = useState('');
    const [location, setLocation] = useState('');
    const [tags, setTags] = useState('');
    const loading = props.loading;
    const setLoading = props.setLoading;
    const setError = props.setError;

    const input = document.getElementById('write-post-location');

    const searchBox = new google.maps.places.Autocomplete(input, {fields: ['name']});

    const filter = new Filter();
    const handlePostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPost(event.target.value);
    };

    const handleDefinitionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefinition(event.target.value);
    };

    const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocation(event.target.value);
    }

    searchBox.addListener('place_changed', () => {
        const places = searchBox.getPlace();
        setLocation(places.name);
    })

    const handleTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTags(event.target.value);
    };

    const handlePost = async event => {
        event.preventDefault();
        setLoading(true);
        if (post.length < 5) {
            setLoading(false);
            setError("Post must be longer than 5 characters");
            return;
        }
        const tag_arr = createTags(tags);
        try {
            await makePost(post, definition, location, tag_arr);
            setLoading(false);
            props.setUpdate(true);
        } catch (error) {
            setLoading(false);
            setError('Unable to make post ' + error);
        } finally {
            setPost("");
            setDefinition("");
            setLocation("");
            setTags('');
        }
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
        >
            <Row>
                <Col xs={9}>
                    <StyledTextField
                        id="write-post"
                        label="Today I Said..."
                        multiline
                        maxRows={4}
                        value={post}
                        onChange={handlePostChange}
                        style={{width: "100%"}}
                    />
                    <StyledTextField
                        className="my-3"
                        id="write-post-definition"
                        label="What does it mean?"
                        value={definition}
                        onChange={handleDefinitionChange}
                        style={{width: "100%"}}
                    />
                    <StyledTextField
                        className="mb-3"
                        id="write-post-location"
                        label="Where did you say it?"
                        value={location}
                        onChange={handleLocationChange}
                        style={{width: "100%"}}
                    />
                    <StyledTextField
                        id="write-post-tags"
                        label="Tags"
                        value={tags}
                        onChange={handleTagsChange}
                        style={{width: "100%"}}
                    />
                </Col>
                <Col xs={3}>
                    <SecondaryButton
                        variant='contained'
                        type="submit"
                        onClick={handlePost}
                        style={{height: "56px", marginLeft: "3%", minWidth: "50%", maxWidth: "100%"}}
                    >
                        {loading && <CircularProgress size={20} style={{marginRight: 20}}/>}
                        Claim It
                    </SecondaryButton>
                </Col>
            </Row>
        </Box>
    )
};

export default WritePost;