import { useState } from "react";

export const useInput = (initialValue: string, required: boolean = false) => {
	const [value, setValue] = useState(initialValue);

	return {
		value,
		setValue,
		reset: () => setValue(""),
		bind: {
			value,
			onChange: (event: React.SyntheticEvent<Element, Event>) => {
				// @ts-ignore
				setValue(event.target.value);
			},
			required: required,
		},
	};
};
