import React, { useEffect, useState } from 'react';
import {getAllPosts, getCurrentUser} from '../Api';
import Post from './Post';
import Error from './Error';
import {PaddedContainer, StyledTextField} from './Styles';
import WritePost from './WritePost';
import Navigation from './Navigation';
import { Row } from 'react-bootstrap';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Explore = () => {
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [allPosts, setAllPosts] = useState([]);
	const [updatePosts, setUpdatePosts] = useState(false);
	const [userLikes, setUserLikes] = useState([]);
	const [userDisikes, setUserDisikes] = useState([]);
	const [search, setSearch] = useState("");
	const [filteredPosts, setFilteredPosts] = useState(allPosts);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	}

	let navigate = useNavigate();

	useEffect(() => {
		async function fetchPosts() {
			try {
				const posts = await getAllPosts();
				setAllPosts(posts);
				setFilteredPosts(posts);
				const user = await getCurrentUser();
				setUserLikes(user.likes);
				setUserDisikes(user.dislikes);
			} catch (error) {
				setLoading(false);
				setError('Error in fetching data: ' + error);
				console.error(error);
				const err_str = error.toString();
				if (err_str.includes("Expired Token")) {
					localStorage.clear();
					navigate("/login");
				}
			}
		}

		fetchPosts();
		setUpdatePosts(false);
	}, [updatePosts]);

	useEffect(() => {
		if (!search) {
			setFilteredPosts(allPosts);
		} else {
			setFilteredPosts(allPosts.filter(function(item) {
				const lower_search = search.toLowerCase();
				return item.content?.toLowerCase().includes(lower_search)
					|| item.definition?.toLowerCase().includes(lower_search)
					|| item.location?.toLowerCase().includes(lower_search)
					|| item.tags?.includes(lower_search)
					|| item.username.toLowerCase().includes(lower_search);
			}));
		}

	}, [search])

	return (
			<div>
				<Navigation search={search} setSearch={setSearch}/>
				<Box className="text-center" style={{backgroundColor: '#f6f6f6', marginTop: "100px"}}>
					<Row className="justify-content-center" style={{ marginRight: 0 }} id="top">
					<p style={{ maxWidth: "80%" }}>Remember that time you made up that new phrase? And your buddy stole it? Then <i>everyone </i>
					started saying it?
					</p>
						</Row>
					<Row className="justify-content-center" style={{ color: '#240061', marginRight: 0}}>
						<h5>#claimyourword #fightforyourphrase</h5>
					</Row>
				</Box>
				<PaddedContainer style={{ background: "linear-gradient(140deg, rgba(36,0,97,1) 0%, rgba(0,226,255,1) 100%)" }}>
					{error ? <Error errorData={error} /> : ''}
					<WritePost setError={setError} error={error} loading={loading} setLoading={setLoading} setUpdate={setUpdatePosts}/>
					<div>
					</div>
				</PaddedContainer>
				<div>
					{
						filteredPosts.map((prop, index) => {
							return (
									Post(prop, index, setUpdatePosts, userLikes, userDisikes)
							)
						})
					}
				</div>
			</div>
	);
};

export default Explore;