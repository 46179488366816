import React from "react";
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { Helmet } from 'react-helmet'
import { ThemeProvider, useTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/lab';
import {theme} from "./components/Styles";

export const setUser = (response) => {
  localStorage.setItem('expiration', Date.parse(response["registered_at"]) + parseInt(response['expires_in']));
  localStorage.setItem('userId', response["user_id"]);
  localStorage.setItem('token', response['access_token']);
};

const App = () => {
  return (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div>
            <Helmet>
              <title>Who Said? I Said!</title>
            </Helmet>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
  );
}
export default App;
