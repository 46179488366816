import React, { useEffect, useState } from 'react';
import Post from './Post';
import Navigation from './Navigation';
import {getAllPostsForUser, getCurrentUser, getUser, getUserByUsername, postProfilePicture, updateUser} from '../Api';
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Row} from 'react-bootstrap';
import {ProfileBox, ProfilePostBox} from "./Styles";
import {Box, Button, IconButton, Modal} from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import EditableTextField from "./EditableTextField";
import EditIcon from "@mui/icons-material/Edit";

const Profile = () => {
	const params = useParams();
	const username = params.username;
	const [error, setError] = useState('');
	const [allPosts, setAllPosts] = useState([]);
	const [userInfo, setUserInfo] = useState({});
	const [updatePosts, setUpdatePosts] = useState(false);
	const [isSelf, setIsSelf] = useState(false);
	const [updatePhoto, setUpdatePhoto] = useState(false);
	const [photo, setPhoto] = useState(false);
	const [preview, setPreview] = useState();
	const [userLikes, setUserLikes] = useState([]);
	const [userDislikes, setUserDislikes] = useState([]);

	async function handleSubmitBio(biography) {
		try {
			let user = await updateUser(localStorage.getItem("userId"), {"biography": biography});
			setUserInfo(user)
		} catch (error) {
			setError('Error in fetching data: ' + error);
			console.error(error);
		}
	}

	async function handleSubmitLocation(location) {
		try {
			let user = await updateUser(localStorage.getItem("userId"), {"location": location});
			setUserInfo(user)
		} catch (error) {
			setError('Error in fetching data: ' + error);
			console.error(error);
		}
	}

	function handleChange(event) {
		event.preventDefault();
		setPhoto(event.target.files[0]);
	}
	async function handleSubmitPhoto(event) {
		event.preventDefault();
		try {
			await postProfilePicture(localStorage.getItem("userId"), photo)
		} catch (error) {
			setError('Error in fetching data: ' + error);
			console.error(error);
		}
		handleClose();
		setUpdatePosts(true);
	}

	const handleClose = () => setUpdatePhoto(false);
	const handleOpen = () => setUpdatePhoto(true);

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 200,
		bgcolor: '#ffffff',
		border: '1px solid #000',
		boxShadow: 24,
		p: 4,
	};

	useEffect(() => {
		if (!photo) {
			setPreview(undefined)
			return
		}

		const objectUrl = URL.createObjectURL(photo)
		setPreview(objectUrl)

		// free memory when ever this component is unmounted
		return () => URL.revokeObjectURL(objectUrl)
	}, [photo])

	let navigate = useNavigate();

	useEffect(() => {
		async function fetchUserData() {
			try {
				let user = {}
				if (username) {
					user = await getUserByUsername(username);
				}
				let uid = user["id"] || localStorage.getItem("userId");
				const posts = await getAllPostsForUser(uid);
				setAllPosts(posts);
				const user_info = await getUser(uid);
				// TODO: Inefficient to call this again
				const currUser = await getCurrentUser();
				setUserInfo(user_info);
				setIsSelf(localStorage.getItem("userId") === user_info.id);
				setUserDislikes(currUser.dislikes);
				setUserLikes(currUser.likes);
				if (isSelf) {
					localStorage.setItem("profilePicture", user_info["profile_picture"])
				}
			} catch (error) {
				setError('Error in fetching data: ' + error);
				console.error(error);
				const err_str = error.toString();
				if (err_str.includes("Expired Token")) {
					localStorage.clear();
					navigate("/login");
				}
			}
		}

		fetchUserData();
		setUpdatePosts(false);
	}, [updatePosts, params]);

	return (
			<div>
				<Navigation/>
				<ProfileBox>
					<Row className="justify-content-center py-2">
						<div id="profile-img">
							<Avatar alt={userInfo.username} src={userInfo.profile_picture || "/static/images/avatar/2.jpg"} variant="rounded"
									style={{ borderRadius: "15px" }}
							/>
							{ isSelf ?
							<div className="overlay">
								<IconButton style={{ top: "40%"}} onClick={handleOpen}>
									<EditIcon/>
								</IconButton>
								<Modal open={updatePhoto} onClose={handleClose}>
									<Box sx={style} className="justify-content-center text-center">
										<form onSubmit={handleSubmitPhoto}>
										<Button
											variant="contained"
											component="label"
											className="my-2"
										>
											Upload File
											<input
												type="file"
												hidden
												onChange={handleChange}
											/>
										</Button>
										<Button variant="contained" type="submit" className="my-2">Submit</Button>
										<img src={preview} style={{ width: "50px", height: "50px"}}/>
										</form>
									</Box>
								</Modal>
							</div> : ""
							}
						</div>
					</Row>
					<Row className="info">
						<h3>{userInfo.username}</h3>
					</Row>
					<Row className="info">
						{
							isSelf ?
							<EditableTextField
								text={userInfo.biography || "Click here to add a bio"}
								handleSubmit={handleSubmitBio}
							/> : <p>{userInfo.biography || "Biography"}</p>
						}
					</Row>
					<Row className="info">
						<LocationOnIcon/>
						{
							isSelf ?
							<EditableTextField
								text={userInfo.location || "Click here to add a location"}
								handleSubmit={handleSubmitLocation}
							/> : <p>{userInfo.location || " "}</p>
						}
					</Row>
				</ProfileBox>
				<ProfilePostBox>
					{
						allPosts.map((prop, index) => {
							return (Post(prop, index, setUpdatePosts, userLikes, userDislikes))
						})
					}
				</ProfilePostBox>
			</div>
	)
}

export default Profile;