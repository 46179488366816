import { styled, createTheme } from '@mui/material/styles';
import { Box, Button, TextField } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import IconButton from "@mui/material/IconButton";

export const theme = createTheme({
	palette: {
		primary: {
			main: '#00E0FF',
			contrastText: 'white'
		},
		secondary: {
			main: '#240061',
			contrastText: 'white'
		}
	}
});

export const FloatingContainer = styled(Box)(({ theme }) => ({
	color: 'white',
	background: 'linear-gradient(140deg, rgba(36,0,97,1) 0%, rgba(0,226,255,1) 100%)',
	padding: '5%',
	borderRadius: '25px',
	margin: '10%'
}));

export const PaddedContainer = styled(Box)(({ theme }) => ({
	color: '#240061',
	backgroundColor: 'white',
	padding: '5%'
}))

export const PostBox = styled(Box)(({ index, theme}) => ({
	color: '#240061',
	backgroundColor: index % 2 === 0 ? "white" : "#f6f6f6",
	// paddingLeft: '3%',
	paddingRight: '5%',
	overflowWrap: 'break-word',
	inlineSize: "100%",
	alignItems: "center",
	display: "flex"
}))

export const LikeButton = styled(IconButton)(({ isliked, theme }) => ({
	// HACK: 'none' is just a failure
	color: isliked ? theme.palette.secondary.main : "none",
	padding: 0
}))

export const DislikeButton = styled(IconButton)(({ isdisliked, theme }) => ({
	// HACK: 'none' is just a failure
	color: isdisliked ? 'red' : "none",
	padding: 0
}))

export const ProfileBox = styled(Box)(({ theme }) => ({
	color: "black",
	backgroundColor: "#EAEAEA",
	paddingLeft: "5%",
	paddingRight: "5%",
	[theme.breakpoints.up('sm')]: {
		position: "fixed",
		height: "100vh",
		width: "30vw",
	},
	[theme.breakpoints.down('sm')]: {
		".info": {
			marginLeft: 0
		},
	},
	marginTop: "100px",
}));

export const ProfilePostBox = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		left: "30vw",
		top: "100px",
		position: "relative"
	},
}))

export const PrimaryButton = styled(Button)(({theme}) => ({
	color: 'white',
	backgroundColor: '#00e2ff',
}))

export const SecondaryButton = styled(Button)(({theme}) => ({
	color: 'white',
	backgroundColor: '#240061'
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
	backgroundColor: 'white',
	borderRadius: '7px'
}))

export const StyledInputRow = styled(Row)(({ theme }) => ({
	paddingRight: '10px',
	paddingTop: '5px',
	paddingBottom: '10px',
	width: '100%'
}))
