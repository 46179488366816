import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import InternalRoutes from './InternalRoutes';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import {theme} from "./components/Styles";
import {ThemeProvider} from "@mui/material/styles";

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
    <ThemeProvider theme={theme}>
      <div style={{
        background: "rgba(255, 255, 255, .7) no-repeat center center fixed",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: -1,
        position: 'fixed'
      }}/>
        <Router>
          <InternalRoutes />
        </Router>
    </ThemeProvider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
