import React, {useState, createElement} from 'react';
import {StyledTextField} from "./Styles";
import {CircularProgress, IconButton, InputAdornment} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from '@mui/icons-material/Edit';

const EditableTextField = ({ text, handleSubmit, size = 'p' }) => {
    const [values, setValues] = useState({
        editing: false,
        loading: false,
        text: text,
        displayEdit: false
    })

    const handleEdit = () => {
        setValues({ ...values, editing: !values.editing });
    }

    const handleLoading = () => {
        setValues({ ...values, loading: !values.loading });
    }

    const handleUpdate = () => event => {
        setValues({ ...values, text: event.target.value });
    }

    const handleSub = async event => {
        event.preventDefault();
        handleLoading();
        await handleSubmit(values.text)
        handleLoading();
        handleEdit();
    }

    // Display as text unless clicked on
    return (
        <div>
            {
                values.editing ?
                    <form onSubmit={handleSub} onBlur={handleEdit}>
                        <StyledTextField
                            value={values.text}
                            onChange={handleUpdate()}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            values.loading ?
                                                <CircularProgress/>:
                                                <div>
                                        <IconButton
                                            edge="end"
                                            aria-label="Cancel"
                                            onClick={handleEdit}
                                        >
                                            <CloseIcon/>
                                        </IconButton>
                                        <IconButton
                                            edge="end"
                                            aria-label="Submit"
                                            type="submit"
                                        >
                                            <CheckIcon/>
                                        </IconButton>
                                                </div>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form> :
                    createElement(
                        size,
                        {
                            onClick: handleEdit,
                            onMouseEnter: () => setValues({...values, displayEdit: true}),
                            onMouseLeave: () => setValues({...values, displayEdit: false})
                        },
                        text,
                        values.displayEdit ?
                        <IconButton
                            aria-label="Edit"
                            type="edit"
                            className="p-0 ml-1"
                        ><EditIcon/></IconButton> : ''
                        )
            }
        </div>
    )
}

export default EditableTextField;