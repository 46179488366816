import post from "./components/Post";

const baseUri = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/' : 'https://api.whosaidisaid.com/'

async function handleErrors(response) {
    if (!response.ok) {
        const data = await response.json()
        throw Error(data["detail"] || response.statusText);
    }
    return response;
}

async function postData(url = '', data = {}, query = new URLSearchParams()) {
    url = !!query ? url + "?" + query.toString() : url;
    if (url.endsWith("?")) {
        url = url.replace("?", "");
    }
    let headers = {'Content-Type': 'application/json'};
    let res_data = JSON.stringify(data);
    if (data instanceof FormData) {
        headers = {};
        res_data = data
    }
    if (localStorage.getItem("token")) {
        headers["Authorization"] = "Bearer ".concat(localStorage.getItem("token"));
    }
    return await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: res_data
    }).then(handleErrors)
        .then(response => response.json())
        .then(data => { return data })
}

async function getData(url = '', query_dict = {}) {
    let headers = {
        // 'Content-Type': 'application/json'
    };
    if (localStorage.getItem("token")) {
        headers["Authorization"] = "Bearer ".concat(localStorage.getItem("token"));
    }
    return await fetch(url + '?' + new URLSearchParams(query_dict), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    }).then(handleErrors)
        .then(response => response.json())
        .then(data => { return data })
}

async function patchData(url = '', data = {}) {
    const headers = {
        'Content-Type': 'application/json'
    }
    if (localStorage.getItem("token")) {
        headers["Authorization"] = "Bearer ".concat(localStorage.getItem("token"));
    }
    return await fetch(url, {
        method: 'PATCH',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    }).then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

async function deleteData(url = '', query = new URLSearchParams()) {
    url = !!query ? url + "?" + query.toString() : url;
    if (url.endsWith("?")) {
        url = url.replace("?", "");
    }
    let headers = {'Content-Type': 'application/json'};
    if (localStorage.getItem("token")) {
        headers["Authorization"] = "Bearer ".concat(localStorage.getItem("token"));
    }
    return await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    }).then(handleErrors)
        .then(response => response.json())
        .then(data => { return data })
}

export function health() {
    fetch(baseUri + 'health').then((response) => console.log(response));
}

// User functions

export function login(username, password) {
    const login_form = new FormData();
    login_form.append('username', username);
    login_form.append('password', password);
    return postData(baseUri + 'users/login', login_form);
}

export function signUp(formData) {
    return postData(baseUri + 'users/signup', formData);
}

export function updateUser(userId, formData) {
    return patchData(baseUri + 'users/user/' + userId, formData);
}

export function getUser(userId) {
    return getData(baseUri + 'users/user/' + userId);
}

export function getCurrentUser() {
    return getData(baseUri + 'users/user/' + localStorage.getItem('userId'));
}


export function deleteUser(userId) {
    return deleteData(baseUri + 'users/user/' + userId);
}

export function getUserByUsername(username) {
    return getData(baseUri + 'users/user/username/' + username);
}

export function postProfilePicture(userId, photo) {
    const data = new FormData();
    data.append("file", photo);
    return postData(baseUri + 'users/user/' + userId + '/picture', data);
}

export function initiateForgotPassword(username) {
    const query = new URLSearchParams({"username": username})
    return postData(baseUri + 'users/user/forgotpassword', {}, query);
}

export function refreshToken() {
    return postData(baseUri + 'users/refresh');
}

export function resetPassword(username, password, confirmation_code) {
    const reset_password = new FormData();
    reset_password.append('username', username);
    reset_password.append('password', password);
    reset_password.append('confirmation_code', confirmation_code)
    return postData(baseUri + 'users/user/resetpassword', reset_password);
}

// Post functions

export function makePost(post_content, post_definition, location="", tags=[]) {
    const query = new URLSearchParams({"user_id": localStorage.getItem("userId")});
    return postData(baseUri + 'posts', {
        "content": post_content,
        "definition": post_definition,
        "location": location,
        "tags": tags
    }, query);
}

export function getAllPosts() {
    return getData(baseUri + 'posts');
}

export function getPost(postId) {
    return getData(baseUri + 'posts/' + postId);
}

export function getAllPostsForUser(userId) {
    return getData(baseUri + 'posts/user/' + userId);
}

export function deletePost(postId) {
    return deleteData(baseUri + 'posts/' + postId);
}

export function editPost(post_id, form_data) {
    return patchData(baseUri + 'posts/' + post_id, form_data);
}

// I know the logic here is fuzzy
// A user can like a post and if it is liked, unlike it
// Or a user can dislike a post and if it is disliked, remove the dislike
export function likePost(post_id: string): Promise<any> {
    return postData(baseUri + 'posts/like', {}, new URLSearchParams({"post_id": post_id}));
}

export function unlikePost(post_id: string): Promise<any> {
    return deleteData(baseUri + 'posts/like', new URLSearchParams({"post_id": post_id}));
}

export function dislikePost(post_id: string): Promise<any> {
    return postData(baseUri + 'posts/dislike', {}, new URLSearchParams({"post_id": post_id}));
}

export function undislikePost(post_id: string): Promise<any> {
    return deleteData(baseUri + 'posts/dislike', new URLSearchParams({"post_id": post_id}));
}