import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import SignUp from './components/SignUp';
import PrivateWrapper from './components/PrivateWrapper';
import Explore from './components/Explore'
import Profile from './components/Profile';
import ForgotPassword from "./components/ForgotPassword";

const InternalRoutes = () =>
      <Routes>
          <Route path="/" element={<Navigate to="/explore" replace/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/signup" element={<SignUp/>} />
          <Route path="/forgotpassword" element={<ForgotPassword/>} />
          <Route element={<PrivateWrapper/>}>
            <Route path="/explore" element={<Explore/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/:username" element={<Profile/>} />
          </Route>
        <Route path="*" element={<Navigate to="/explore" replace />}/>
      </Routes>

export default InternalRoutes;
