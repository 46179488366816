import React from 'react';
import Avatar from '@mui/material/Avatar';
import {PostBox, LikeButton, DislikeButton} from './Styles';
import {Link} from "react-router-dom";
import EditableTextField from "./EditableTextField";
import {dislikePost, editPost, likePost, undislikePost, unlikePost} from "../Api";
import {createTags} from "./WritePost";
import { ThumbUp, ThumbUpOffAlt, ThumbDown, ThumbDownOffAlt } from '@mui/icons-material';
import {abbrNum} from "../utils/utils";

const Post = (props, index, update = null, userLikes = [], userDislikes = []) => {
	const isSelf = props.user_id === localStorage.getItem("userId");
	const post_date = new Date(props.created_at);

	async function handleSubmitPostTitle(cont) {
		if (cont.length < 5) {
			return;
		}
		try {
			await editPost(props.id, {"content": cont});
			update(true);
		} catch (error) {
			console.error(error);
		}
	}

	async function handleSubmitPostDefinition(def) {
		try {
			await editPost(props.id, {"definition": def});
			update(true);
		} catch (error) {
			console.error(error);
		}
	}

	async function handleSubmitPostTags(tags) {
		try {
			const tag_arr = createTags(tags)
			await editPost(props.id, {"tags": tag_arr});
			update(true);
		} catch (error) {
			console.error(error);
		}
	}

	async function toggleLike() {
		try {
			if (userLikes.includes(props.id)) {
				await unlikePost(props.id);
			} else {
				await likePost(props.id);
			}
			update(true);
		} catch (error) {
			console.error(error);
		}
	}

	async function toggleDislike() {
		try {
			if (userDislikes.includes(props.id)) {
				await undislikePost(props.id);
			} else {
				await dislikePost(props.id);
			}
			update(true);
		} catch (error) {
			console.error(error);
		}
	}

	return (
			<PostBox index={index} key={index}>
				<div style={{ alignItems: "center", maxWidth: "8%", paddingLeft: "25px", paddingRight: "25px" }}>
					<LikeButton isliked={userLikes.includes(props.id)} onClick={toggleLike}>
						{userLikes.includes(props.id) ? <ThumbUp/> : <ThumbUpOffAlt/>}
					</LikeButton>
					{/* Show the net like */}
					<p style={{ marginBottom: 0 }}>{abbrNum(props.likes - props.dislikes)}</p>
					<DislikeButton isdisliked={userDislikes.includes(props.id)} onClick={toggleDislike}>
						{userDislikes.includes(props.id) ? <ThumbDown/> : <ThumbDownOffAlt/>}
					</DislikeButton>
				</div>
				<div>
					{ isSelf ? <EditableTextField text={props.content} handleSubmit={handleSubmitPostTitle} size={'h4'} style={{ flex: 1, flexWrap: "wrap", paddingTop: "10px" }}/>
						: <h4 style={{ flex: 1, flexWrap: "wrap", paddingTop: "10px" }}>{props.content}</h4>
					}
					{ isSelf ? <EditableTextField text={props.definition} handleSubmit={handleSubmitPostDefinition} size={'p'} style={{ flex: 1, flexWrap: "wrap", marginBottom: 0 }}/>
						: <p style={{ flex: 1, flexWrap: "wrap", marginBottom: 0 }}>{props.definition}</p>
					}
					<p style={{ flex: 1, flexWrap: "wrap", marginBottom: 0 }}>{props.location}</p>
					{ isSelf ? <EditableTextField text={props.tags || [].join(" ")} handleSubmit={handleSubmitPostTags} size={'p'} style={{ fontSize: 14, marginBottom: 0 }}/>
						: <p style={{ fontSize: 14, marginBottom: 0  }}>{props.tags || [].join(" ")}</p>
					}
					<div style={{ display: "flex", alignItems: "center", flexWrap: "wrap"}}>
						<Avatar alt={props.username} src={props.profile_picture || "/static/images/avatar/2.jpg"} style={{ height: "25px", width: "25px" }}/>
						<p style={{ color: "gray", marginLeft: "10px", paddingTop: "10px" }}>
							<Link to={`/${props.username}`} style={{ textDecoration: "none", color: "black"}}>{props.username} </Link>
							said it on
							<span style={{ color: "black"}}> {post_date.toLocaleString('default', { month: 'short', day: "numeric", year: "numeric" })}</span>
						</p>
					</div>
				</div>
			</PostBox>
	);
}

export default Post;