import React from 'react'
import { Alert } from '@mui/material';

const Error = ({ errorData }) => {
	return (
			<div className='row justify-content-lg-center my-3' style={{ width: '100%' }}>
				<Alert style={{ borderRadius: '15px' }} variant="filled" severity="error">{errorData}</Alert>
			</div>
	);
};

export default Error;
