import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateWrapper = () => {
	const location = useLocation();
	return localStorage.getItem('token') !== null ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />;
};


export default PrivateWrapper;
