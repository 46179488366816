import React, { useState } from 'react'
import {Box, CircularProgress, IconButton, InputAdornment} from "@mui/material";
import {FloatingContainer, PrimaryButton, StyledInputRow, StyledTextField} from "./Styles";
import Error from "./Error";
import {initiateForgotPassword, login, resetPassword} from "../Api";
import Message from "./Message";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {setUser} from "../App";
import {useNavigate} from "react-router-dom";
import {Row} from "react-bootstrap";

const ForgotPassword = () => {
    const [codeSent, setCodeSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [values, setValues] = useState({
        username: '',
        password: '',
        confirm_password: '',
        confirmation_code: '',
        showPassword: false,
        message: null
    })

    const navigate = useNavigate();

    /** Handler to change values based on TextField input. */
    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    }

    const handleInitiatePasswordReset = async event => {
        event.preventDefault();
        setError('');
        setLoading(true);
        let response;
        try {
            response = await initiateForgotPassword(values.username);
            setLoading(false);
            setValues({ ...values, message: `Please check your ${response === "SMS" ? 'phone' : 'email'} for a code to reset your password`})
            setCodeSent(true);
        } catch (error) {
            setLoading(false);
            setError('Something went wrong');
            console.error('Error.js: ', error);
        }
    }

    const handleResetPassword = async event => {
        event.preventDefault();
        setError('')
        setLoading(true);
        let response;
        try {
            response = await resetPassword(values.username, values.password, values.confirmation_code);
            setLoading(false);
            navigate("/login");
        } catch (error) {
            setLoading(false);
            setError('Unable to reset password ' + error);
            console.error('Error.js: ', error);
        }
    }

    return (
        <FloatingContainer>
        {error ? <Error errorData={error} /> : ''}
        {values.message ? <Message messageData={values.message} /> : '' }
        {
            !codeSent ? <div>
                <StyledInputRow>
                    <StyledTextField
                        id="filled-email-input"
                        label="Username"
                        type="username"
                        autoComplete="Username"
                        value={values.username}
                        onChange={handleChange('username')}
                        variant="filled"
                        fullWidth/>
                    <PrimaryButton
                        variant='contained'
                        disabled={loading}
                        onClick={handleInitiatePasswordReset}
                    >
                    {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
                    Get Code
                </PrimaryButton>
                </StyledInputRow>
            </div> :
                <div>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleResetPassword}
                    >
                        <StyledInputRow>
                            <StyledTextField
                                id="filled-email-input"
                                label="Username"
                                type="username"
                                autoComplete="Username"
                                value={values.username}
                                onChange={handleChange('username')}
                                variant="filled"
                                fullWidth />
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="filled-adornment-password"
                                label="New Password"
                                type={values.showPassword ? 'text' : 'password'}
                                margin="normal"
                                autoComplete="Password"
                                value={values.password}
                                onChange={handleChange('password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="filled"
                                fullWidth
                            />
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="filled-adornment-password"
                                label="Confirm New Password"
                                type={values.showPassword ? 'text' : 'password'}
                                margin="normal"
                                autoComplete="Password"
                                value={values.password}
                                onChange={handleChange('confirm_password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="filled"
                                fullWidth
                            />
                        </StyledInputRow>
                        <StyledInputRow>
                            <StyledTextField
                                id="confirmation-code"
                                label="Confirmation Code"
                                type="text"
                                value={values.confirmation_code}
                                onChange={handleChange('confirmation_code')}
                                variant="filled"
                                fullWidth />
                        </StyledInputRow>
                        <Row>
                            <PrimaryButton
                                variant='contained'
                                disabled={loading}
                                type="submit"
                            >
                                {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
                                Reset Password
                            </PrimaryButton>
                        </Row>
                    </Box>
                </div>
        }
        </FloatingContainer>
    );
}

export default ForgotPassword;